<template lang="pug">
.sw-saberhunt-section--active-clues-wrap(v-if='clues.length')
  page-section.sw-saberhunt-section.sw-saberhunt-section--active-clues.page-pb-md.page-pt-md(
    align='center',
    class='md:page-pt-lg'
  )
    template(slot='content')
      .clues-section.page-mb-md
        .clues-section-title.d-inline-block.relative.fw-bold.text-center.fs-xxl(class='md:fs-xxxl lg:fs-mega lg:page-px-lg')
          | Location {{ campaign.id }} Clues
      .clues-section-list.d-flex.flex-wrap.align-items-start(
        :class='`justify-content-${clues.length < 3 ? "center" : "start"}`'
      )
        .clues-section-list-item-wrap.page-mb-sm-alt(v-for='clue in clues', :key='clue.id')
          .clues-section-list-item-inner.page-mx-xs-alt.text-left
            .clues-section-list-item-header.d-flex.align-items-center.fs-xxl.fw-bold.page-pl-sm
              span Clue {{ clue.id }}
            .clues-section-list-item-tweet(:is='clue.template')
</template>
<script>
export default {
  props: {
    campaign: {
      type: Object,
      required: true
    },
    clues: {
      type: Array,
      required: true
    }
  }
}
</script>
