<template lang="pug">
page-section.page-py-md(align='center')
  template(slot='content')
    .h1.fs-xl.mb-0(class='md:fs-xxxl lg:fs-mega') How To Win
    .d-flex.flex-column.flex-md-row.align-items-start.justiy-content-center.page-px-sm
      .page-mt-md.text-left.w-50--after-md(class='md:page-pr-sm')
        .h3.fw-bold.color-sw-holocron-gold To be eligible to win you must:
        ul.page-mt-xs.fs-md
          li
            span.pr-1 Be 18 or older and be a legal resident of an eligible country.
            a.copy-link(:href='rulesLink', target='_blank') See Official Rules.
          li
            | Have a
            |
            a.copy-link(:href='$surf.links.giveaways', target='_blank') Surf Giveaways account
          li Have completed the game by unlocking the “Trust Only in the Force” achievement/trophy from <span class="italic">Star Wars</span> Jedi: Fallen Order&trade; (PC, Xbox, PlayStation)
          li
            | Find the location of the lightsaber based on the clues posted on the
            |
            a.copy-link(href='https://twitter.com/EAStarWars', target='_blank') EA <span class="italic">Star Wars</span>
            |
            | social media accounts
          li Be the first to arrive (after the 3rd Clue has been announced) to qualify as the grand prize winner. The next 25 participants to arrive will qualify as runners-up
          //- li The next 25 people that meet the eligibility requirements will win digital copies of <span class="italic">Star Wars</span> Jedi: Survivor&trade; after the game's release
          li Answer a simple skill-testing question when you arrive

      .page-mt-md.text-left.w-50--after-md(class='md:page-pl-sm')
        .h3.fw-bold.color-sw-holocron-gold What you should bring to the location:
        ul.page-mt-xs.fs-md
          li
            | A mobile device with:
            ul
              li
                | Xbox/PlayStation/Steam app(s) downloaded to show proof of the unlocked achievement/trophy
                ul
                  li Other PC platforms will be available on-site to login
          li A valid official government issued photo ID (driver's license, passport, etc.) proving age and legal residence
          li Your Surf Giveaways account logged in to the platform
</template>
<script>
export default {
  props: {
    rulesLink: {
      type: String,
      required: true
    }
  }
}
</script>
