<template lang="pug">
tweet-embed
  template(slot='tweet')
    blockquote.twitter-tweet(data-theme='dark')
      p(lang='en', dir='ltr')
        | Help is all around. One just needs to know where to look for the first clue.
        br
        br
        | &#x1F449;
        a(href='https://t.co/NBwHswSHUM') https://t.co/NBwHswSHUM
        a(href='https://twitter.com/hashtag/SWJediSurvivorSaberHunt?src=hash&ref_src=twsrc%5Etfw') #SWJediSurvivorSaberHunt
        a(href='https://t.co/MOsBrpUFCP') pic.twitter.com/MOsBrpUFCP
      | &mdash; EA Star Wars (@EAStarWars)
      a(href='https://twitter.com/EAStarWars/status/1625268660494385152?ref_src=twsrc%5Etfw') February 13, 2023
</template>
<script>
// @see https://help.twitter.com/en/using-twitter/how-to-embed-a-tweet
// @see https://html-to-pug.com/
import TweetEmbed from '../../../../../components/TweetEmbed.vue'

export default {
  components: {
    TweetEmbed
  }
}
</script>
