<template lang="pug">
.tweet-embed-wrap
  slot(name='tweet')
</template>
<script>
// @see https://help.twitter.com/en/using-twitter/how-to-embed-a-tweet
// @see https://html-to-pug.com/
var TwitterWidgetsLoader = require('twitter-widgets')

export default {
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    TwitterWidgetsLoader.load(this.loadedCallback)
  },
  methods: {
    loadedCallback() {
      this.isLoaded = true
      this.$emit('loaded')
    }
  }
}
</script>
