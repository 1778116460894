<template lang="pug">
page-section(align='center')
  template(v-if='campaigns.length', slot='content')
    .h1.fs-xl(class='md:fs-xxxl lg:fs-mega') Congratulations to the Winners!
    .d-flex.flex-wrap.align-items-stretch.justify-content-center.page-mt-md
      .location-display-img-wrap.page-mb-md(v-for='campaign in campaigns', :key='campaign.id')
        img.location-display-img(:src='locationDisplayImgSrc(campaign)', :alt='campaign.locationName')
</template>
<script>
export default {
  props: {
    campaigns: {
      type: Array,
      required: true
    }
  },
  methods: {
    locationDisplayImgSrc(campaign) {
      return campaign.imgSrc[campaign.state]
    }
  }
}
</script>
