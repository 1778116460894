<template lang="pug">
page.sw-saberhunt(
  body-classes='sw-saberhunt',
  page-meta-title='STAR WARS - The Saber Hunt',
  :show-header='false',
  :ga-event-category='gaEventCategory',
  :ga-event-label='gaEventLabel'
)
  //- Content
  template(slot='content')
    //- Banner
    page-section.sw-saberhunt-section.sw-saberhunt-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
        .banner-img-content(v-if='!isEndedView')
          b-btn(variant='sw-white-outline', :href='learnMoreLink', target='_blank') Learn More About The Game

    #main
      //- All Locations Section
      all-locations-section.sw-saberhunt-section.sw-saberhunt-section--all-locations.page-pt-lg.page-pb-md(
        v-if='campaigns.length',
        :campaigns='campaigns'
      )

      //- [Conditional] Ended Section
      page-section.sw-saberhunt-section.sw-saberhunt-section--ended.page-pb-lg.page-pt-sm(
        v-if='isEndedView',
        align='center'
      )
        template(slot='content')
          .h1 THANK YOU FOR PARTICIPATING!
          .page-pt-md Learn more about <span class='italic'>Star Wars</span> Jedi: Survivor&trade;
          b-btn.page-mt-sm(variant='sw-gold', :href='learnMoreLink', target='_blank') Click Here

      //- [Conditional] Prize (when not ended)
      prize-display(v-else-if='activeOrUpcomingCampaign')

      //- [Conditional] How To Win Section (when not ended)
      how-to-win-section.sw-saberhunt-section.sw-saberhunt-section--how-to-win(
        v-if='!isEndedView && activeOrUpcomingCampaign',
        :rules-link='rulesLink'
      )

      //- [Conditional] Clues
      active-clues(
        v-if='isActiveView && activeOrUpcomingCampaign && clues.length',
        :campaign='activeOrUpcomingCampaign',
        :clues='clues'
      )

      //- Video Section
      video-section.sw-saberhunt-section.sw-saberhunt-section--video

      //- Disclaimer
      page-section.sw-saberhunt-section.fs-xs.text-center.page-pt-md-alt(align='center')
        template(slot='content')
          span.fw-bold.pr-1 NO PURCHASE NECESSARY.
          span Subject to&nbsp;
            a.copy-link(:href='rulesLink', target='_blank', @mousedown='trackEvent("to_rules__footer")') Official Rules
            | . Void where prohibited.

  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
      li(slot='extra-nav-li')
        a(:href='rulesLink', target='_blank', @mousedown='trackEvent("to_rules__footer")') Official Rules
      .mt-3.mt-sm-0(slot='custom-image')
        img.mr-2(:src='esrbRatingSrc', height='60', alt='ESRB Rating')
</template>
<script>
import moment from 'moment'
import PrizeDisplay from './components/PrizeDisplay.vue'
import ActiveClues from './components/ActiveClues.vue'
import AllLocationsSection from './components/AllLocationsSection.vue'
import HowToWinSection from './components/HowToWinSection.vue'
import VideoSection from './components/VideoSection.vue'
import Clue1 from './components/clues/location-3/Clue1.vue'
import Clue2 from './components/clues/location-3/Clue2.vue'
import Clue3 from './components/clues/location-3/Clue3.vue'
import BaseFooter from '../../components/BaseFooter.vue'
import gaEventMixin from '../../mixins/ga_event'

const ACTIVE_STATE = 'active'
const UPCOMING_STATE = 'upcoming'
const ENDED_STATE = 'ended'

// Date checking: https://www.timestamp-converter.com/

export default {
  name: 'SaberHunt',
  mixins: [gaEventMixin],
  data() {
    return {
      // Base
      activeIntervalPtr: null,
      esrbRatingSrc: require('../../assets/themes/ea-saberhunt/images/esrb-rating--pending.png'),
      learnMoreLink: 'https://www.ea.com/games/starwars/jedi/jedi-survivor',
      rulesLink:
        'https://playr-production-web-assets-0.s3.us-west-2.amazonaws.com/custom_rules/Star+Wars+Jedi_+Survivor+-+The+Saber+Hunt+-+Physical+Rules.pdf',
      // Page State
      mainViewState: ENDED_STATE,
      clues: [
        { id: '01', template: Clue1 },
        { id: '02', template: Clue2 },
        { id: '03', template: Clue3 }
      ],
      // Campaign manifest
      campaigns: [
        {
          id: 1,
          state: ENDED_STATE,
          endDate: '2023-02-06T15:00:00.000Z', // 7am PT
          imgSrc: {
            upcoming: require('../../assets/themes/ea-saberhunt/images/location-1-upcoming.png'),
            active: require('../../assets/themes/ea-saberhunt/images/location-1-active.png'),
            ended: require('../../assets/themes/ea-saberhunt/images/location-1-ended.png')
          }
        },
        {
          id: 2,
          state: ENDED_STATE,
          endDate: '2023-02-09T21:00:00.000Z', // 1pm PT
          imgSrc: {
            upcoming: require('../../assets/themes/ea-saberhunt/images/location-2-upcoming.png'),
            active: require('../../assets/themes/ea-saberhunt/images/location-2-active.png'),
            ended: require('../../assets/themes/ea-saberhunt/images/location-2-ended.png')
          }
        },
        {
          id: 3,
          state: ENDED_STATE,
          endDate: '2023-02-16T04:00:00.000Z', // 8pm PT
          imgSrc: {
            upcoming: require('../../assets/themes/ea-saberhunt/images/location-3-upcoming.png'),
            active: require('../../assets/themes/ea-saberhunt/images/location-3-active.png'),
            ended: require('../../assets/themes/ea-saberhunt/images/location-3-ended.png')
          }
        }
      ]
    }
  },
  computed: {
    activeOrUpcomingCampaign() {
      return this.campaigns.find((c) => [ACTIVE_STATE, UPCOMING_STATE].includes(c.state))
    },
    activeCampaign() {
      return this.campaigns.find((c) => c.state === ACTIVE_STATE)
    },
    allCampaignsEnded() {
      return this.campaigns.every((c) => c.state === ENDED_STATE)
    },
    isUpcomingView() {
      return this.mainViewState === UPCOMING_STATE
    },
    isActiveView() {
      return this.mainViewState === ACTIVE_STATE
    },
    isEndedView() {
      return this.mainViewState === ENDED_STATE
    }
  },
  // created() {
  //   this.activeCampaignEndedCheck()
  //   this.activeIntervalPtr = setInterval(this.activeCampaignEndedCheck, 1000 * 10) // every 10s
  // },
  methods: {
    activeCampaignEndedCheck() {
      if (!this.activeCampaign) {
        return
      }

      if (
        this.activeCampaign.endDate &&
        this.activeCampaign.state !== ENDED_STATE &&
        moment().isAfter(moment(this.activeCampaign.endDate))
      ) {
        this.activeCampaign.state = ENDED_STATE
      }

      if (this.allCampaignsEnded) {
        this.mainViewState = ENDED_STATE
      }
    }
  },
  components: {
    PrizeDisplay,
    ActiveClues,
    HowToWinSection,
    AllLocationsSection,
    VideoSection,
    Clue1,
    Clue2,
    Clue3,
    BaseFooter
  }
}
</script>
<style lang="scss" src="../../assets/themes/ea-saberhunt/scss/main.scss" />
