<template lang="pug">
tweet-embed
  template(slot='tweet')
    blockquote.twitter-tweet(data-theme='dark')
      p(lang='en', dir='ltr')
        | One last shot for The
        a(href='https://twitter.com/hashtag/SWJediSurvivorSaberHunt?src=hash&ref_src=twsrc%5Etfw') #SWJediSurvivorSaberHunt
        | !
        br
        br
        | Be the first to find our location for a chance to win Cal&rsquo;s Lightsaber. Come prepared with proof of game completion.
        br
        br
        | &#x1F449;
        a(href='https://t.co/NBwHswSHUM') https://t.co/NBwHswSHUM
        a(href='https://t.co/YLsehV6Zuk') pic.twitter.com/YLsehV6Zuk
      | &mdash; EA Star Wars (@EAStarWars)
      a(href='https://twitter.com/EAStarWars/status/1626023582303133696?ref_src=twsrc%5Etfw') February 16, 2023
</template>
<script>
// @see https://help.twitter.com/en/using-twitter/how-to-embed-a-tweet
// @see https://html-to-pug.com/
import TweetEmbed from '../../../../../components/TweetEmbed.vue'

export default {
  components: {
    TweetEmbed
  }
}
</script>
