<template lang="pug">
page-section.sw-saberhunt-section.sw-saberhunt-section--prize.page-pb-sm.page-pt-md(align='center', class='md:page-pt-md-alt')
  template(slot='content')
    .mx-auto.text-center
      img.active-graphic(:src='prizeImgSrc', alt='Saber Hunt Prize Package')
</template>
<script>
export default {
  data() {
    return {
      prizeImgSrc: require('../../../assets/themes/ea-saberhunt/images/prize-graphic.png')
    }
  }
}
</script>
